import { ApolloClient, NormalizedCacheObject } from 'apollo-boost'

import { DayOfWeek, StoreOpeningTime } from '../../../../generated/graphql'
import { StoreDetails as STORE_DETAILS_QUERY } from '../../../../graphql/Query/Content/StoreDetails.graphql'
import { STORE_MOCK, StoreDetailsData } from '../../../data/StoreMocks'
import { ClientBehaviours } from '../../../utils'
import { ResolverProvider } from '../../types'

export enum Status {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  PERMANENTLY_CLOSED = 'PERMANENTLY_CLOSED',
  TEMPORARILY_CLOSED = 'TEMPORARILY_CLOSED',
}

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const isStoreOpen = (openingTimes: StoreOpeningTime[]) => {
  const currentDate = new Date(new Date().toUTCString())

  const currentDay = openingTimes.find(
    (openingTime) => openingTime.day === DayOfWeek[days[currentDate.getDay()]],
  )

  if (!currentDay) {
    return Status.CLOSED
  }

  const startDate = new Date(currentDate.getTime())
  startDate.setHours(currentDay.openingTime.split(':')[0])
  startDate.setMinutes(currentDay.openingTime.split(':')[1])
  startDate.setSeconds(0)

  const endDate = new Date(currentDate.getTime())
  endDate.setHours(currentDay.closingTime.split(':')[0])
  endDate.setMinutes(currentDay.closingTime.split(':')[1])
  endDate.setSeconds(0)

  return startDate < currentDate && endDate > currentDate
    ? Status.OPEN
    : Status.CLOSED
}

export const openingTimes = (openingTimes) => {
  openingTimes.map((times, index) => {
    if (times.openingTime === times.closingTime) {
      openingTimes.splice(index, 1, { ...times, closed: true })
    }
  })
  return openingTimes
}

export const StoreByStoreUrlTag: ResolverProvider = (
  client: ApolloClient<NormalizedCacheObject>,
  {
    behaviours,
  }: {
    behaviours: ClientBehaviours
  },
) => async (_, args): Promise<StoreDetailsData | null> => {
  if (behaviours.override) {
    return STORE_MOCK
  }

  try {
    const { data } = await client.query({
      query: STORE_DETAILS_QUERY,
      variables: args,
    })

    const store = data?.storeByStoreUrlTag

    if (!store) {
      console.error('Store not found for URL:', args)
      return null
    }

    return {
      store: {
        ...data.storeByStoreUrlTag,
        status: data.storeByStoreUrlTag?.openingTimes
          ? isStoreOpen(data.storeByStoreUrlTag.openingTimes)
          : Status.CLOSED,
        openingTimes: openingTimes(data.storeByStoreUrlTag.openingTimes),
        nearbyStores:
          data.storeByStoreUrlTag?.nearbyStores?.map((store) => {
            return {
              ...store,
              status: isStoreOpen(store.openingTimes),
            }
          }) || [],
      },
    }
  } catch (error) {
    console.error('Error fetching store details:', error)
    return null
  }
}
